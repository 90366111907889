import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Tabs extends React.Component {
  static propTypes = {
    color: PropTypes.oneOf(['dark', 'light']),
    activeTab: PropTypes.any,
    className: PropTypes.string,
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIndicator);
    this.updateIndicator();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIndicator);
  }

  constructor(props) {
    super(props);

    this.container = React.createRef();
    this.indicator = React.createRef();
    this.state = {
      prevIndex: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.updateIndicator();
    }
  }

  get classNames() {
    const { className, color } = this.props;

    return classNames('tabs', {
      [className]: className,
      'is-light': color === 'light',
      'is-dark': color === 'dark',
    });
  }

  get indicatorClassNames() {
    const { color } = this.props;

    return classNames('tab-indicator', {
      'is-light': color === 'light',
      'is-dark': color === 'dark',
    });
  }

  updateIndicator = () => {
    clearTimeout(this.timeout);

    /* Set delay of debouncer to 750ms if IE */
    this.delay = this.delay || (window.browser.isIE && 750) || 100;

    this.timeout = setTimeout(() => {
      const el = this.container.current.querySelector(`.tab[data-key="${this.props.activeTab}"]`);
      const index = Array.prototype.indexOf.call(this.container.current.children, el);

      const leftPosition = el.offsetLeft;
      const rightPosition = this.container.current.getBoundingClientRect().width - el.offsetLeft - el.getBoundingClientRect().width;
      let [rightDelay, leftDelay] = [90,0];

      if (this.state.prevIndex < index) {
        [rightDelay, leftDelay] = [0,90];
      }

      $.Velocity(this.indicator.current, { left: leftPosition }, { duration: 200, easing: 'easeOutQuad', delay: leftDelay });
      $.Velocity(this.indicator.current, { right: rightPosition }, { duration: 200, easing: 'easeOutQuad', delay: rightDelay, queue: false });
      this.setState({ prevIndex: index });
    }, this.delay);
  }

  render() {
    return (
      <div className={this.classNames} ref={this.container}>
        {this.props.children}
        <div className={this.indicatorClassNames} ref={this.indicator} />
      </div>
    )
  }
}
