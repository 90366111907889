import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class TabContent extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
  }

  get classNames() {
    const { className, active } = this.props;

    return classNames('tab-content', {
      [className]: className,
      'is-active': active,
    });
  }

  render() {
    return (
      <div className={this.classNames}>
        {this.props.children}
      </div>
    )
  }
}
