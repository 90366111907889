import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Tab extends React.Component {
  static propTypes = {
    tabKey: PropTypes.any,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    active: false,
    onClick: function() {},
  }

  get classNames() {
    const { className, active } = this.props;

    return classNames({
      [className]: className,
      'is-active': active,
      'tab': true,
    });
  }

  handleOnClick = (e) => {
    e.preventDefault();
    this.props.onClick(this.props.tabKey);
  }

  render() {
    return (
      <button
        data-key={this.props.tabKey}
        onClick={this.handleOnClick}
        className={this.classNames}
      >
        <h3 className='tab-text'>{this.props.children}</h3>
      </button>
    )
  }
}
