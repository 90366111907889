import Model from './model';
import Query from './query';

export default class ResendConfirmation extends Model {
  static URL = '/api/v2/resend_confirmation';

  // Custom model. Used for resending email confirmations only
  static send(params) {
    const query = new Query(this);
    return query.params(params).get();
  }
}