import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

// Modules
import Window from '../../../../../../modules/window';

// Base
import Button from '../../../../base/button';
import Col from '../../../../base/col';
import Row from '../../../../base/row';

// Form
import Input from '../../../../form/input';

export default class ForgotPassword extends React.Component {
  static propTypes = {
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  state = {
    user: {},
    errorMessages: {},
    sending: false,
  }

  handleInputChange = (field, value) => {
    const { user } = this.state;
    const newUser = update(user, { [field]: { $set: value } });
    this.setState({ user: newUser });
  }

  handleSubmit = (e) => {
    const { onComplete } = this.props;
    const { sending, user } = this.state;

    e && e.preventDefault();

    if (sending) return;

    this.setState({ sending: true }, () => {
      $.ajax({
        url: '/users/password',
        type: 'POST',
        data: {
          user: user,
        },
        success: () => {
          Window.flash.success('You will receive an email with instructions on how to reset your password in a few minutes.');
          onComplete();
        },
        error: ({ responseJSON }) => {
          this.setState({
            errorMessages: responseJSON.errors,
            sending: false,
          });
        },
      });
    })
  }

  render() {
    const { onCancel } = this.props;
    const { errorMessages, sending } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Row className='margin-20' noMargin>
          <Col s={12}>
            <Input {...{
              id: 'forgot_email',
              icon: faUser,
              label: 'Email',
              type: 'email',
              errorMessage: errorMessages.email,
              onChange: (_, value) => this.handleInputChange('email', value),
              name: 'user[email]'
            }} />
          </Col>
          <Col s={12} className='margin-bottom-20'>
            Already a member?
            <a
              href='#'
              className='margin-left-5 hover-dark-primary'
              onClick={onCancel}
           >Sign In</a>
          </Col>
          <Col s={12}>
            <Button
              id='send_reset_instructions'
              text='Send Reset Instructions'
              onClick={this.handleSubmit}
              disabled={sending}
              block
            />
          </Col>
        </Row>
        <button type='submit' className='hide' />
      </form>
    )
  }
}