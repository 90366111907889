import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Base
import Card from '../../base/card';
import Tab from '../../base/tab';
import TabContent from '../../base/tab/content';
import Tabs from '../../base/tabs';

//Related Components
import SignIn from './sign_in_up/sign_in';
import SignUp from './sign_in_up/sign_up';

export default class SignInUp extends React.Component {
  static propTypes = {
    forgotPassword: PropTypes.bool,
    activeTab: PropTypes.number,
    consentFormId: PropTypes.number,
    tokenId: PropTypes.string,
    opportunityId: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    allowUserType: PropTypes.bool,
    onChangeUserType: PropTypes.func,
    onSignIn: PropTypes.func,
    onSignUp: PropTypes.func,
    setActiveTab: PropTypes.func,
    userType: PropTypes.string,
    domain: PropTypes.string,
  }

  static defaultProps = {
    forgotPassword: false,
    activeTab: 1,
    allowUserType: false,
    onChangeUserType: () => { },
    setActiveTab: () => { },
    domain: '',
  }

  constructor(props) {
    super(props);

    // Default tab to be shown
    this.state = {
      activeTab: props.activeTab,
    };
  }

  isActive = (tab) => {
    const { activeTab } = this.state;
    return tab === activeTab;
  }

  handleClick = (activeTab) => {
    this.props.setActiveTab(activeTab);
    this.setState({ activeTab });
  }

  render() {
    const {
      email, firstName, lastName, forgotPassword, opportunityId, consentFormId, allowUserType,
      onChangeUserType, tokenId, onSignIn, onSignUp, userType, domain,
    } = this.props;
    const { activeTab } = this.state;

    return (
      <Card
        id='sign_in_up'
        color='light'
        noPadding
        className='width-450 margin-center'
      >
        <Tabs {...{
          activeTab
        }}>
          <Tab {...{
            tabKey: 1,
            active: this.isActive(1),
            onClick: () => this.handleClick(1),
            className: classNames({ 'primary-color': this.isActive(1) }),
          }}>Sign In</Tab>
          <Tab {...{
            tabKey: 2,
            active: this.isActive(2),
            onClick: () => this.handleClick(2),
            className: classNames({ 'primary-color': this.isActive(2) }),
          }}>Create Account</Tab>
        </Tabs>
        <TabContent active={this.isActive(1)}>
          <SignIn {...{
            domain,
            forgotPassword,
            opportunityId,
            onSignIn,
          }} />
        </TabContent>
        <TabContent active={this.isActive(2)}>
          <SignUp {...{
            domain,
            opportunityId,
            consentFormId,
            tokenId,
            email,
            firstName,
            lastName,
            allowUserType,
            onChangeUserType,
            onSignUp,
            userType,
          }} />
        </TabContent>
      </Card>
    )
  }
}