import React from 'react';
import PropTypes from 'prop-types';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

// Modules
import Window from '../../../../../../modules/window';

// Models
import ResendConfirmationModel from '../../../../../../models/v2/resend_confirmation';

// Base
import Button from '../../../../base/button';
import Col from '../../../../base/col';
import Row from '../../../../base/row';

// Form
import Input from '../../../../form/input';

export default class ResendConfirmation extends React.Component {
  static propTypes = {
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  state = {
    email: '',
    errorMessages: {},
    sending: false,
  }

  handleInputChange = (email) => {
    this.setState({ email });
  }

  handleSubmit = (e) => {
    e && e.preventDefault();

    const { onComplete } = this.props;
    const { sending, email } = this.state;

    if (sending) return;

    this.setState({ sending: true }, () => {
      const data = { email };

      ResendConfirmationModel
        .send(data)
        .then(() => {
          Window.flash.success('Your confirmation email has been resent');
          onComplete();
        })
        .catch((error) => {
          this.setState({
            sending: false,
          }, () => Window.flash.error(error));
        });
    })
  }

  render() {
    const { onCancel } = this.props;
    const { errorMessages, sending } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Row className='margin-20' noMargin>
          <Col s={12}>
            <Input {...{
              id: 'forgot_email',
              icon: faUser,
              label: 'Email',
              type: 'email',
              errorMessage: errorMessages.email,
              onChange: (_, value) => this.handleInputChange(value),
            }} />
          </Col>
          <Col s={12} className='margin-bottom-20'>
            Already confirmed?
            <a
              className='margin-left-5 hover-primary'
              onClick={onCancel}
           >Sign In</a>
          </Col>
          <Col s={12}>
            <Button
              id='send_reset_instructions'
              text='Resend Confirmation'
              onClick={this.handleSubmit}
              disabled={sending}
              block
            />
          </Col>
        </Row>
        <button type='submit' className='hide' />
      </form>
    )
  }
}